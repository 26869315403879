import * as React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useStore from "store/hook";
import { Dialog, TextField } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";

export default function DateTimePicker({
  inspectionCreateTime,
  setInspectionCreateTime,
  setAddInspectionData,
  addInspectionData,
}) {
  const { store, setDialogue } = useStore();

  const { isOpen = false, action } =
    store?.app?.dialogs?.openDateTimePicker || {};

  // const initialdate = dayjs(
  //   inspectionCreateTime ? Number(inspectionCreateTime * 1000) : Date.now()
  // );

  const initialdate = React.useMemo(() => {
    if (action == "addinspection") {
      return dayjs(
        addInspectionData?.createdTime
          ? Number(addInspectionData?.createdTime * 1000)
          : Date.now()
      );
    } else {
      return dayjs(
        inspectionCreateTime ? Number(inspectionCreateTime * 1000) : Date.now()
      );
    }
  }, [inspectionCreateTime, isOpen, addInspectionData?.createdTime]);

  const [value, setValue] = React.useState(() => dayjs(initialdate));

  React.useEffect(() => {
    if (initialdate) {
      setValue(dayjs(initialdate));
    }
  }, [initialdate]);

  const handleClose = () => {
    setDialogue({ type: "openDateTimePicker", isOpen: false });
  };

  const handleDateChange = (newValue) => {
    if (action == "addinspection") {
      const initialdate = dayjs(addInspectionData?.createdTime  * 1000);

      if (newValue) {
        const updatedDate = initialdate
          .year(newValue.year())
          .month(newValue.month())
          .date(newValue.date());

        setValue(updatedDate);

        setAddInspectionData((prev) => ({
          ...prev,
          createdTime: Math.floor(updatedDate.valueOf() / 1000),
        }));
      }
    } else {
      const initialdate = dayjs(inspectionCreateTime * 1000);

      if (newValue) {
        const updatedDate = initialdate
          .year(newValue.year())
          .month(newValue.month())
          .date(newValue.date());

        setValue(updatedDate);

        let updatedTimestamp;

        updatedTimestamp = setInspectionCreateTime(
          Math.floor(updatedDate.valueOf() / 1000)
        );

        setDialogue({
          type: "updateSelectedDate",
          timestamp: updatedTimestamp,
        });
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={isOpen} onClose={handleClose}>
        <div style={{ padding: "20px" }}>
          <StaticDatePicker
            onAccept={handleDateChange}
            onClose={handleClose}
            value={value}
            onChange={handleDateChange}
          />
        </div>
      </Dialog>
    </LocalizationProvider>
  );
}

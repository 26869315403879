// @mui material components
import Card from "@mui/material/Card";
import { Checkbox, Menu, MenuItem } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import SoftPagination from "components/SoftPagination";
import UserRow, { Inspectioncolumns } from "store/action/Reports";
import React, { useState } from "react";
import DeleteInspection from "components/SoftDialog/DeleteInspection";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";
// Data
import useStore from "store/hook";
import api from "store/api/api";
import headerBg from "assets/images/curved-images/Header.png";
import CircularLoader from "components/SoftDialog/Loader";
import { Label } from "@mui/icons-material";

const INSPECTIONS_TYPE = {
  All: "",
  "New Report": "new_report",
  "Under Review": "under_manager_review",
  "Final Review": "under_editor_final_review",
  "Ready For Distribution": "ready_for_distribution",
  Archived: "archived",
};

function Reports() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const { setDialogue, store } = useStore();
  const [role, setRole] = React.useState("All");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [status, setStatus] = useState();
  const [deletedAccount, setDeletedAccount] = useState("");

  const { inspectionId } = store?.app?.dialogs?.deleteInspection || {};
  const { success } = store?.app?.dialogs?.isSuccess || {};

  React.useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    } else {
      getReports();
    }
  }, [navigate, page, status, success]);

  const getReports = async () => {
    try {
      setIsLoading(true);
      const response = await api.getInspectionList({
        page,
        status,
      });

      if (response.status === 1) {
        setRows(
          response.data.inspections.map((report) =>
            UserRow(report, setDialogue)
          )
        );
        setPagination(response.data.pagination);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    const apiValue = INSPECTIONS_TYPE[value];
    setRole(value);
    setAnchorEl(null);
    setStatus(apiValue);
    setPage(1);

    if (value === "All") {
      getReports();
    } else {
      getReports();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    setPage(1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteInspection type="inspection" />
      <SoftBox pt={1} mb={2.5}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "sticky",
                top: 0,
                zIndex: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={2}
            >
              <SoftTypography
                color="white"
                variant="h6"
                fontSize="20px"
                fontWeight="light"
              >
                Total Inspections -{" "}
                <SoftTypography component="span" variant="h6" fontSize="20px">
                  {pagination?.totalInspections || 0}
                </SoftTypography>
              </SoftTypography>

              <SoftBox display="flex" gap={2}>
                <SoftBox mb={0} sx={{ position: "relative" }}>
                  <SoftBox
                    onClick={handleClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 12px",
                      border: "0.5px solid #6A707C",
                      borderRadius: "6px",
                      cursor: "pointer",
                      width: "290px",
                      backgroundColor: "white.main",
                    }}
                  >
                    <SoftTypography
                      fontSize="16px"
                      sx={{
                        color: "lightBlack.main",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "220px",
                      }}
                    >
                      {role}
                    </SoftTypography>
                    <IoIosArrowDown
                      size={20}
                      style={{
                        marginLeft: "auto",
                        color: "primary.main",
                      }}
                    />
                  </SoftBox>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      sx: {
                        mt: 0.5,
                        border: "1px solid black",
                        borderBottom: "none",
                        padding: 0,
                        borderRadius: 0,
                        width: "290px",
                        boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    {[
                      "All",
                      "New Report",
                      "Under Review",
                      "Final Review",
                      "Ready For Distribution",
                      "Archived",
                    ].map((option) => (
                      <MenuItem
                        key={option}
                        onClick={() => handleMenuItemClick(option)}
                        sx={{
                          borderRadius: 0,
                          padding: "14px 12px",
                          borderBottom: "1px solid black",
                          fontSize: "14px",
                          color: "primary.main",
                          "&:hover": {
                            backgroundColor: "none !important",
                          },
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </SoftBox>

                <SoftBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 11px",
                    borderRadius: "6px",
                    cursor: "pointer",
                    backgroundColor: "white.main",
                  }}
                >
                  <SoftBox position="relative" mr={0.5}>
                    <Checkbox
                      {...Label}
                      checked={deletedAccount == "yes" ? true : false}
                      onClick={() => {
                        handleChange();
                        setDeletedAccount((prev) => (prev == "" ? "yes" : ""));
                      }}
                    />
                  </SoftBox>
                  <SoftTypography
                    fontSize="16px"
                    sx={{
                      color: "lightBlack.main",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Deleted Inspections
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              sx={{
                position: "relative",
                "& .MuiTableContainer-root": {
                  maxHeight: rows.length > 0 ? "calc(100vh - 245px)" : "auto",
                  overflow: rows.length > 0 ? "auto" : "visible",
                },
                "& .MuiTableHead-root": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {isLoading ? (
                <SoftBox
                  display="flex"
                  height="calc(100vh - 180px)"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <SoftTypography variant="subtitle1" color="textSecondary">
                    <CircularLoader type="large" />
                  </SoftTypography>
                </SoftBox>
              ) : rows.length > 0 ? (
                <Table columns={Inspectioncolumns} rows={rows} />
              ) : (
                <SoftBox
                  sx={{
                    height: "calc(100vh - 182px)",
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    gap={8}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      backgroundColor="shadow.main"
                    >
                      {Inspectioncolumns.map(
                        ({ name, align, width }, index) => (
                          <SoftBox
                            key={index}
                            width={width}
                            align={align}
                            fontWeight="bold"
                            fontSize="18px"
                            py="14px"
                            px="24px"
                          >
                            {name}
                          </SoftBox>
                        )
                      )}
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SoftTypography
                        fontSize="90px"
                        fontWeight="light"
                        height="110px"
                        color="fade"
                      >
                        0
                      </SoftTypography>
                      <SoftTypography
                        fontSize="40px"
                        fontWeight="medium"
                        color="fade"
                        pb={2}
                      >
                        REPORTS
                      </SoftTypography>
                      <EmptyFolderIcon style={{ opacity: 0.8 }} />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>
            {rows.length > 0 && !isLoading && (
              <SoftBox
                sx={{
                  backgroundColor: "#ffffff",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 2,
                }}
              >
                <SoftPagination
                  Totalpages={pagination.totalPages}
                  currentPage={pagination.currentPage}
                  changePage={setPage}
                />
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Reports;

const colors = {
  background: {
    default: "#F7F9FB",
  },

  shadow: {
    main: "#F2F2F2",
    focus: "#F2F2F2",
  },

  input: {
    main: "#F1F4F8",
    focus: "#F1F4F8",
  },

  text: {
    main: "#737373",
    focus: "#737373",
  },

  transparent: {
    main: "#00000000",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#212121",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#02255B",
    focus: "#02255B",
  },

  secondary: {
    main: "#8392ab",
    focus: "#96a2b8",
  },

  info: {
    main: "#024E24",
    focus: "#024E24",
  },

  success: {
    main: "#4DA664",
    focus: "#4DA664",
  },

  warning: {
    main: "#FF3A3A",
    focus: "#FF3A3A",
  },

  error: {
    main: "#C95A5A",
    focus: "#C95A5A",
  },

  light: {
    main: "#e9ecef",
    focus: "#e9ecef",
  },

  dark: {
    main: "#344767",
    focus: "#344767",
  },

  lightOrange: {
    main: "#FFA5A5",
    focus: "#FFA5A5",
  },

  darkOrange: {
    main: "#FF4646",
    focus: "#FF4646",
  },

  lightBlack: {
    main: "#212121",
    focus: "#212121",
  },

  border: {
    main: "#6A707C",
    focus: "#6A707C",
  },

  yellow: {
    main: "#EDBB33",
    focus: "#EDBB33",
  },
  fade:{
    main: "#CACACA",
    focus: "#CACACA",
  },


  grey: {
    100: "#f8f9fa",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#02255B",
      state: "#02255B",
    },
    
    download:{
      main: "#434341",
      state: "#434341"
    },

    secondary: {
      main: "#627594",
      state: "#a8b8d8",
    },

    info: {
      main: "#024E24",
      state: "#024E24",
    },

    nap: {
      main: "#8A2BE2",
      state: "#7054eb",
    },

    success: {
      main: "#097521",
      state: "#17ad37",
    },

    warning: {
      main: "#EDBB33",
      state: "#EDBB33",

    },

    error: {
      main: "#D81F1F",
      state: "#D81F1F",
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4",
    },

    dark: {
      main: "#141727",
      state: "#3a416f",
    },
    lightOrange: {
      main: "#FFA5A5",
      focus: "#FFA5A5",
    },
  
    darkOrange: {
      main: "#FF4646",
      focus: "#FF4646",
    },
  
    lightBlack: {
      main: "#212121",
      focus: "#212121",
    },
  
    border: {
      main: "#6A707C",
      focus: "#6A707C",
    },
  
    yellow: {
      main: "#EDBB33",
      focus: "#EDBB33",
    },
    fade: {
      main: "#CACACA",
      focus: "#CACACA",
    },

    darkyellow:{
      main: "",
      focus: ""
   },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  alertColors: {
    primary: {
      main: "#7928ca",
      state: "#d6006c",
      border: "#efb6e2",
    },

    secondary: {
      main: "#627594",
      state: "#8ca1cb",
      border: "#dadee6",
    },

    info: {
      main: "#2152ff",
      state: "#02c6f3",
      border: "#b9ecf8",
    },

    nap: {
      main: "#2152ff",
      state: "#02c6f3",
      border: "#b9ecf8",
    },

    success: {
      main: "#17ad37",
      state: "#84dc14",
      border: "#daf3b9",
    },

    warning: {
      main: "#f53939",
      state: "#fac60b",
      border: "#fef1c2",
    },

    error: {
      main: "#ea0606",
      state: "#ff3d59",
      border: "#f9b4b4",
    },

    light: {
      main: "#ced4da",
      state: "#d1dae6",
      border: "#f8f9fa",
    },

    dark: {
      main: "#141727",
      state: "#2c3154",
      border: "#c2c8d1",
    },
  },

  badgeColors: {
    primary: {
      background: "#f883dd",
      text: "#a3017e",
    },

    secondary: {
      background: "#e4e8ed",
      text: "#5974a2",
    },

    info: {
      background: "#abe9f7",
      text: "#08a1c4",
    },

    success: {
      background: "#cdf59b",
      text: "#67b108",
    },

    warning: {
      background: "#fef5d3",
      text: "#fbc400",
    },

    error: {
      background: "#fc9797",
      text: "#bd0000",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  inputColors: {
    borderColor: { main: "#6A707C", focus: "#6A707C" },
    boxShadow: "#02255B",
    error: "#fd5c70",
    success: "#66d432",
  },

  sliderColors: {
    thumb: { borderColor: "#d9d9d9" },
  },

  circleSliderColors: {
    background: "#d3d3d3",
  },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;

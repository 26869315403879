// / @mui material components
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkIcon from "@mui/icons-material/Link";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import checkboxgreen from "assets/images/logos/checkboxgreen.png";
import checkboxwhite from "assets/images/logos/checkboxwhite.png";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import useStore from "store/hook";
import InspectionSection from "../updateInspection/components/InspectionSection";
import headerBg from "assets/images/curved-images/Header.png";
import SoftAvatar from "components/SoftAvatar";
import api from "store/api/api";
import formatStringWithSpaces from "../../services/stringconverter";
import getTimeFromMilliseconds from "../../services/timeconverter";
import truncateString from "services/truncatestring";
import DeleteInspection from "components/SoftDialog/DeleteInspection";
import { Grid, Menu, MenuItem, TextField } from "@mui/material";
import Discipline from "../updateInspection/components/Discipline";
import SelectDicipline from "components/SoftDialog/SelectDicipline";
import AddDicipline from "components/SoftDialog/AddDicipline";
import PredefinedComment from "components/SoftDialog/PredefinedComment";
import AddLocation from "components/SoftDialog/AddLocation";
import SelectInspection from "components/SoftDialog/SelectInspection";
import formatPhoneNumber from "services/formatPhoneNo";
import convertMillisecondsToDate from "services/dateconverter";
import AddIcon from "@mui/icons-material/Add";
import ResponsiveTimePickers from "../updateInspection/components/TimePicker";
import { toast } from "react-toastify";
import { uploadImages } from "services/firebase";
import CircularLoader from "components/SoftDialog/Loader";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateTimePicker from "../updateInspection/components/DateTimePicker";
import getDateFromSeconds from "services/getDate";

function AddInspection() {
  const { setDialogue } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [mainLoading, setMainLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [discipline, setDiscipline] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [referenceData, setReferenceData] = useState({});
  const [addInspectionData, setAddInspectionData] = useState({
    deficiencies_found: [],
    weather_condition: "",
    report_number: "",
    temperature: "",
    arrival_time: "",
    left_time: "",
    is_reinspection: "",
    inspection_refference_id: "",
    discipline_data: [],
    createdTime: "",
  });

  console.log("addInspection", addInspectionData);

  const fetchProjectDetail = async () => {
    try {
      setIsLoading(true);
      const response = await api.getProjectDetail({
        project_id: id,
      });
      setProjectData(response.data.project);
    } catch (error) {
      console.error("Error fetching project detail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectDetail();
  }, [navigate, id]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenSelectInspection = () => {
    setDialogue({
      type: "selectInspection",
      isOpen: true,
      inspectionId: "",
    });
  };

  const handleOpenDiciplineSelect = (index) => {
    setDialogue({
      type: "selectDicipline",
      isOpen: true,
      selectedDicipline: index,
    });
  };

  const handleUploadImage = async () => {
    const imageFiles = newImages;

    if (imageFiles?.length > 0) {
      const newUploadedImages = await uploadImages(imageFiles, setDiscipline);

      const newdisciplie = [
        {
          comment: discipline[0]?.comment,
          disciplineName: discipline[0]?.disciplineName,
          images: [...discipline[0]?.images, ...newUploadedImages],
          inspectionType: discipline[0]?.inspectionType,
          location: discipline[0]?.location,
          notes: discipline[0]?.notes,
        },
      ];
      return newdisciplie;
    } else {
      const disciplineData = [
        {
          comment: discipline[0]?.comment,
          disciplineName: discipline[0]?.disciplineName,
          images:
            discipline[0]?.images?.length > 0 ? [...discipline[0]?.images] : [],
          inspectionType: discipline[0]?.inspectionType,
          location: discipline[0]?.location,
          notes: discipline[0]?.notes,
        },
      ];

      return disciplineData;
    }
  };

  const handleAddInspection = async () => {
    if (
      (addInspectionData?.is_reinspection == "yes" &&
        addInspectionData?.inspection_refference_id == undefined) ||
      ""
    ) {
      toast.error(
        "Reinspection required value is yes, and you have not selected reispection information"
      );
      setIsLoading(false);
      return;
    }

    if(addInspectionData?.weather_condition == ""){
      toast.error("Please select the weather condtion");
      return;
    }

    if(addInspectionData?.temperature == ""){
      toast.error("Please enter the temperature");
      return;
    }

    if(addInspectionData?.arrival_time == ""){
      toast.error("Please select the arrival time");
      return;
    }

    if(addInspectionData?.left_time == "") {
      toast.error("Please select the left time");
      return;
    }

    setIsLoading(true);

    const newDisciplineData = await handleUploadImage();

    const response = await api.editInspection({});

    if (response?.status == 1) {
      toast.success("Inspection Added Successfully");
      navigate(`/project-overview/${id}`);
    } else {
      const modifiedMessage = formatStringWithSpaces(response?.message);
      toast.error(modifiedMessage);
    }
    setIsLoading(false);
  };

  const handleMenuItemClick = (selectedWeather) => {
    setAddInspectionData((prev) => ({
      ...prev,
      weather_condition: selectedWeather,
    }));
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteInspection type="inspection" />
      <ResponsiveTimePickers
        addInspectionData={addInspectionData}
        setAddInspectionData={setAddInspectionData}
      />

      <DateTimePicker
        setAddInspectionData={setAddInspectionData}
        addInspectionData={addInspectionData}
      />

      <Discipline />

      <SoftBox py={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}
            >
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <Link to="/projects" style={{ color: "white" }}>
                  <SoftTypography
                    component="span"
                    fontWeight="light"
                    variant="h6"
                    fontSize="20px"
                  >
                    Project &gt;{" "}
                  </SoftTypography>
                </Link>
                Add Inspection
              </SoftTypography>
            </SoftBox>

            {mainLoading ? (
              <SoftBox
                display="flex"
                height="calc(100vh - 180px)"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="subtitle1" color="textSecondary">
                  <CircularLoader type="large" />
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox>
                <SoftBox
                  px={{ xs: 2, md: 3 }}
                  py={2}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                  justifyContent="space-between"
                >
                  <SoftBox display="flex" flexDirection="column" gap={1} pr={5}>
                    <SoftTypography
                      color="info"
                      fontWeight="bold"
                      fontSize="36px"
                      textTransform="capitalize"
                    >
                      {projectData?.name} - {projectData?.project_number}
                    </SoftTypography>

                    {/* Inspection Date and Time */}
                    {/* <SoftBox
                  sx={{
                    backgroundColor: "#F1F4F8",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    marginBottom: "4px",
                    width: "100%",
                  }}
                >
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ minWidth: 600 }}
                    gap="8px"
                  >
                    <SoftBox display="flex" alignItems="center" gap={1}>
                      <SoftTypography
                        fontSize="16px"
                        color="lightblack"
                        textTransform="capitalize"
                        fontWeight="bold"
                      >
                        Inspection:
                      </SoftTypography>
                      <SoftTypography
                        fontSize="16px"
                        color="primary"
                        textTransform="capitalize"
                        fontWeight="medium"
                      >
                        {inspectionData?.inspection_number}
                      </SoftTypography>
                    </SoftBox>

                    <SoftTypography
                      fontSize="16px"
                      color="primary"
                      textTransform="capitalize"
                      fontWeight="medium"
                    >
                      {convertMillisecondsToDate(inspectionCreateTime)}{" "}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox> */}

                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                      gap={12}
                    >
                      <SoftBox>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Project Manager
                        </SoftTypography>

                        <SoftTypography
                          color="lightBlack"
                          fontSize="16px"
                          display="flex"
                          gap={2}
                          textTransform="capitalize"
                          sx={{}}
                        >
                          {projectData?.manager?.length > 0
                            ? projectData?.manager?.map((manager, index) => (
                                <Link
                                  to={`/accounts/profile/${manager?._id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <SoftBox
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      cursor: "pointer",
                                      gap: "3px",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    <SoftAvatar
                                      src={manager?.profile_picture || ""}
                                      alt="profile Image"
                                      size="lg"
                                      variant="rounded"
                                    />
                                    <SoftTypography
                                      className="inspector-name"
                                      variant="text"
                                      fontSize="14px"
                                      color="#212121"
                                      textTransform="capitalize"
                                    >
                                      {truncateString(manager?.full_name, 25)}
                                    </SoftTypography>
                                  </SoftBox>
                                </Link>
                              ))
                            : ""}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Location
                        </SoftTypography>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="14px"
                          textTransform="capitalize"
                          mt={2}
                        >
                          {projectData?.location}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox
                    display="flex"
                    py={1.5}
                    flexDirection="column"
                    gap={2}
                  >
                    <SoftButton
                      variant="gradient"
                      color="primary"
                      sx={{
                        padding: "0px",
                        width: "190px",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                      onClick={handleAddInspection}
                    >
                      {isLoading == true ? (
                        <CircularLoader type="small" />
                      ) : (
                        "Add Inspection"
                      )}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                  gap={1}
                  px={3}
                  pb={4}
                >
                  {projectData?.internal_notes && (
                    <SoftBox
                      border="0.5px solid #000000"
                      borderRadius="6px"
                      padding="10px"
                      maxHeight="130px"
                      minHeight="130px"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: 200,
                          md: 300,
                          lg: 550,
                          xl: 550,
                        },
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        "&::-webkit-scrollbar": { display: "none" },
                      }}
                    >
                      <SoftTypography
                        color="lightBlack"
                        fontSize="16px"
                        fontWeight="bold"
                      >
                        Internal Notes
                      </SoftTypography>
                      <SoftTypography
                        color="lightBlack"
                        fontSize="10px"
                        fontWeight="medium"
                        paddingTop="5px"
                      >
                        {projectData?.internal_notes}
                      </SoftTypography>
                    </SoftBox>
                  )}

                  <SoftBox
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    {projectData?.links?.length > 0 && (
                      <SoftBox
                        width="250px"
                        sx={{ px: { xs: 0, md: 2, lg: 2, xl: 2 } }}
                      >
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Project Links
                        </SoftTypography>
                        {projectData?.links?.map((link, index) => (
                          <SoftTypography
                            key={index}
                            color="primary"
                            fontSize="14px"
                            display="inline-flex" // ✅ Ensures text wraps next to the icon
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="start"
                            sx={{
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              maxWidth: "100%",
                            }}
                          >
                            <Link
                              to={link}
                              target="_blank"
                              sx={{ textDecoration: "none", color: "inherit" }}
                            >
                              <SoftBox
                                display="flex"
                                justifyContent="center"
                                sx={{ color: "primary.main" }}
                              >
                                <LinkIcon
                                  sx={{
                                    color: "primary.main",
                                    marginRight: "8px",
                                    width: 16,
                                    height: 16,
                                    transform: "rotate(-40deg)",
                                    flexShrink: 0,
                                  }}
                                />
                                {link}
                              </SoftBox>
                            </Link>
                          </SoftTypography>
                        ))}
                      </SoftBox>
                    )}

                    {projectData?.site_contacts?.length > 0 && (
                      <SoftBox width="350px">
                        <SoftTypography
                          color="#212121"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Site Contacts
                        </SoftTypography>
                        {projectData?.site_contacts?.map((contact, index) => (
                          <SoftBox
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                              backgroundColor: "#F1F4F8",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginBottom: "4px",
                              overflowX: "auto",
                              overflowY: "hidden",
                              whiteSpace: "nowrap",
                              scrollbarWidth: "none",
                              "&::-webkit-scrollbar": { display: "none" },
                            }}
                          >
                            <SoftBox
                              display="flex"
                              flexDirection="column"
                              color="primary"
                            >
                              <SoftBox
                                display="flex"
                                alignItems="center"
                                justifyContent="start"
                                gap="8px"
                              >
                                <SoftTypography
                                  fontSize="14px"
                                  color="primary"
                                  textTransform="uppercase"
                                  fontWeight="medium"
                                >
                                  {contact?.name}
                                </SoftTypography>

                                <SoftTypography
                                  fontSize="12px"
                                  color="primary"
                                  textTransform="capitalize"
                                  fontWeight="medium"
                                >
                                  {contact?.companyName}
                                </SoftTypography>
                              </SoftBox>

                              <SoftBox display="flex" gap="8px">
                                {contact?.email && (
                                  <a
                                    href={`mailto:${contact?.email}`}
                                    sx={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                  >
                                    <SoftBox
                                      display="flex"
                                      gap="8px"
                                      color="primary"
                                    >
                                      <EmailIcon color="primary" />
                                      <SoftTypography
                                        fontSize="14px"
                                        color="primary"
                                      >
                                        {contact?.email}
                                      </SoftTypography>
                                    </SoftBox>
                                  </a>
                                )}

                                {contact?.phoneNo && (
                                  <SoftBox
                                    display="flex"
                                    gap="8px"
                                    color="primary"
                                  >
                                    <PhoneIcon color="primary" />
                                    <SoftTypography
                                      fontSize="14px"
                                      color="primary"
                                    >
                                      {contact?.country_code}{" "}
                                      {formatPhoneNumber(contact?.phoneNo)}
                                    </SoftTypography>
                                  </SoftBox>
                                )}
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))}
                      </SoftBox>
                    )}
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  px={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <SoftBox
                    display="flex"
                    gap="2px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SoftBox position="relative" mt={1} mr={0.5}>
                      {addInspectionData?.is_reinspection === "yes" ? (
                        <SoftBox
                          component="img"
                          src={checkboxgreen}
                          sx={{
                            width: 20,
                            height: 20,
                            objectFit: "cover",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setAddInspectionData((prev) => ({
                              ...prev,
                              is_reinspection:
                                prev?.is_reinspection == "no" ? "yes" : "no",
                            }));
                            setReferenceData({});
                          }}
                        />
                      ) : (
                        <SoftBox
                          component="img"
                          src={checkboxwhite}
                          sx={{
                            width: 20,
                            height: 20,
                            objectFit: "cover",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setAddInspectionData((prev) => ({
                              ...prev,
                              is_reinspection:
                                prev?.is_reinspection == "no" ||
                                prev?.is_reinspection == ""
                                  ? "yes"
                                  : "no",
                            }))
                          }
                        />
                      )}
                    </SoftBox>

                    <SoftTypography
                      color="lightBlack"
                      fontSize="20px"
                      fontWeight="bold"
                      sx={{ color: "#212121" }}
                    >
                      Is this a Re-inspection?
                    </SoftTypography>
                  </SoftBox>

                  {addInspectionData?.is_reinspection === "yes" && (
                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ cursor: "pointer" }}
                      onClick={handleOpenSelectInspection}
                    >
                      <SoftTypography
                        color="primary"
                        fontSize="16px"
                        fontWeight="medium"
                      >
                        {JSON.stringify(referenceData) !== "{}"
                          ? "Change Inspection"
                          : "Choose Inspection"}
                      </SoftTypography>

                      <SoftTypography color="primary" sx={{ mt: "6px" }}>
                        <ChevronRightIcon
                          sx={{
                            width: 20,
                            height: 20,
                          }}
                        />
                      </SoftTypography>
                    </SoftBox>
                  )}
                </SoftBox>

                {addInspectionData?.is_reinspection == "yes" && (
                  <>
                    {JSON.stringify(referenceData) !== "{}" ? (
                      <SoftBox
                        px={3}
                        display="flex"
                        mt="8px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SoftBox
                          border="0.5px solid #02255B"
                          borderRadius="6px"
                          padding="10px"
                          width="100%"
                          backgroundColor="#F1F4F8"
                        >
                          <SoftTypography
                            color="lightBlack"
                            fontSize="16px"
                            fontWeight="medium"
                          >
                            Inspection Detail
                          </SoftTypography>

                          <SoftBox
                            display="flex"
                            width="100%"
                            sx={{
                              flexDirection: { xs: "column", sm: "row" },
                              gap: { xs: "2px", sm: "5px" },
                            }}
                          >
                            <SoftBox display="flex" gap="3px" width="100%">
                              <SoftTypography
                                color="lightBlack"
                                fontSize="16px"
                                fontWeight="medium"
                              >
                                Inspection Number :
                              </SoftTypography>
                              <SoftTypography
                                color="lightBlack"
                                fontSize="16px"
                                fontWeight="regular"
                              >
                                {referenceData?.inspection_number}
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox
                              display="flex"
                              gap="3px"
                              width="100%"
                              sx={{ padding: { sm: "5px", xs: "0px" } }}
                            >
                              <SoftTypography
                                color="lightBlack"
                                fontSize="16px"
                                fontWeight="regular"
                              >
                                {referenceData?.weather_condition},{" "}
                                {referenceData?.temperature}°F
                              </SoftTypography>
                              <SoftTypography
                                color="lightBlack"
                                fontSize="16px"
                                fontWeight="medium"
                              >
                                : Weather
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>

                          <SoftTypography
                            color="lightBlack"
                            fontSize="14px"
                            fontWeight="normal"
                          >
                            {referenceData?.deficiencies_found === "no"
                              ? "There is no deficiencies found."
                              : referenceData?.deficiencies_found === "yes"
                              ? "There is deficiencies found"
                              : formatStringWithSpaces(
                                  referenceData?.deficiencies_found.join(", ")
                                )}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    ) : (
                      <SoftBox
                        px={3}
                        display="flex"
                        mt="8px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SoftBox
                          border="0.5px solid #02255B"
                          borderRadius="6px"
                          padding="10px"
                          width="100%"
                          height="90px"
                          backgroundColor="#F1F4F8"
                        >
                          <SoftTypography
                            color="lightBlack"
                            fontSize="16px"
                            fontWeight="medium"
                          >
                            Inspection Detail
                          </SoftTypography>

                          <SoftTypography
                            color="lightBlack"
                            fontSize="14px"
                            fontWeight="normal"
                          >
                            You haven't selected inspection detail at the
                            moment. Please choose inspection to import data.
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    )}
                  </>
                )}

                <SoftBox
                  display="flex"
                  gap={5}
                  mt="20px"
                  px={3}
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <SoftBox width="100%">
                    <SoftTypography fontWeight="bold" fontSize="20px" mb={0.5}>
                      Inspection Date
                    </SoftTypography>
                    <TextField
                      value={
                        addInspectionData?.createdTime
                          ? getDateFromSeconds(addInspectionData?.createdTime)
                          : "Select Inspection Date"
                      }
                      onClick={() =>
                        setDialogue({
                          type: "openDateTimePicker",
                          isOpen: true,
                          action: "addinspection",
                        })
                      }
                      name="name"
                      placeholder="Inspection date"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        sx: {
                          border: "1px solid #6A707C",
                          backgroundColor: "#FFFFFF",
                          paddingX: "14px", // Adjust left/right padding
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontSize: "16px",
                          color: "#02255B",
                          paddingY: "16px",
                          minHeight: "28px",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </SoftBox>

                  <SoftBox width="100%">
                    <SoftTypography fontWeight="bold" fontSize="20px" mb={0.5}>
                      Inspection Time
                    </SoftTypography>
                    <TextField
                      value={
                        addInspectionData?.createdTime
                          ? getTimeFromMilliseconds(
                              addInspectionData?.createdTime
                            )
                          : "Select Inspection Time"
                      }
                      onClick={() =>
                        setDialogue({
                          type: "openTimePicker",
                          isOpen: true,
                          action: "addInsectiontime",
                        })
                      }
                      name="name"
                      placeholder="Inspection Time"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        sx: {
                          border: "1px solid #6A707C",
                          backgroundColor: "#FFFFFF",
                          paddingX: "14px",
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontSize: "16px",
                          color: "#02255B",
                          paddingY: "16px",
                          minHeight: "28px",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  gap={5}
                  mt="20px"
                  px={3}
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <SoftBox width="100%">
                    <SoftTypography fontWeight="bold" fontSize="20px" mb={0.5}>
                      Weather Condition
                    </SoftTypography>
                    {/* <TextField
                  value={inspectionDetails?.weather}
                  onChange={(e) =>
                    setInspectionDetails((prev) => ({
                      ...prev,
                      weather: e?.target?.value,
                    }))
                  }
                  name="name"
                  placeholder="Enter Weather"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    sx: {
                      border: "1px solid #6A707C",
                      backgroundColor: "#FFFFFF",
                      paddingX: "14px", // Adjust left/right padding
                    },
                  }}
                  inputProps={{
                    sx: {
                      fontSize: "16px",
                      color: "#02255B",
                      paddingY: "16px",
                      minHeight: "28px",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                /> */}

                    <SoftBox
                      onClick={handleClick}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "9px 12px",
                        border: "0.5px solid #6A707C",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                    >
                      <SoftTypography
                        fontSize="16px"
                        sx={{
                          padding: "0px 0px 0px 0px",
                          color: "#02255B",
                        }}
                      >
                        {addInspectionData?.weather_condition ||
                          "Select Weather"}
                      </SoftTypography>
                      {anchorEl == null ? (
                        <IoIosArrowDown
                          size={20}
                          style={{
                            marginLeft: "auto",
                            color: "#02255B",
                          }}
                        />
                      ) : (
                        <IoIosArrowUp
                          size={20}
                          style={{
                            marginLeft: "auto",
                            color: "#02255B",
                          }}
                        />
                      )}
                    </SoftBox>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                      PaperProps={{
                        sx: {
                          border: "1px solid #6A707C",
                          borderBottom: "none",
                          padding: 0,
                          borderRadius: "6px",
                          width: anchorEl?.offsetWidth,
                          boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      {["Clear", "Rainy", "Cloudy", "Snow"].map((option) => (
                        <MenuItem
                          key={option}
                          onClick={() => handleMenuItemClick(option)}
                          sx={{
                            borderRadius: 0,
                            padding: "14px 12px",
                            borderBottom: "1px solid #6A707C",
                            fontSize: "14px",
                            color: "primary.main",
                            "&:hover": {
                              backgroundColor: "none !important",
                            },
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </SoftBox>

                  <SoftBox width="100%">
                    <SoftTypography fontWeight="bold" fontSize="20px" mb={0.5}>
                      Temperature(°F):
                    </SoftTypography>
                    <TextField
                      value={addInspectionData?.temperature}
                      onChange={(e) =>
                        setAddInspectionData((prev) => ({
                          ...prev,
                          temperature: e?.target?.value,
                        }))
                      }
                      name="name"
                      placeholder="Enter Temperature"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        sx: {
                          border: "1px solid #6A707C",
                          backgroundColor: "#FFFFFF",
                          paddingX: "14px",
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontSize: "16px",
                          color: "#02255B",
                          paddingY: "16px",
                          minHeight: "28px",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  gap={5}
                  mt="20px"
                  px={3}
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <SoftBox width="100%">
                    <SoftTypography fontWeight="bold" fontSize="20px" mb={0.5}>
                      Time Arrived
                    </SoftTypography>
                    <TextField
                      value={
                        addInspectionData?.arrival_time
                          ? getTimeFromMilliseconds(
                              addInspectionData?.arrival_time
                            )
                          : "Select Arrival Time"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDialogue({
                          type: "openTimePicker",
                          isOpen: true,
                          action: "addarrival",
                        });
                      }}
                      name="name"
                      placeholder="Enter Arrived Time"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        sx: {
                          border: "1px solid #6A707C",
                          backgroundColor: "#FFFFFF",
                          paddingX: "14px", // Adjust left/right padding
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontSize: "16px",
                          color: "#02255B",
                          paddingY: "16px",
                          minHeight: "28px",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </SoftBox>

                  <SoftBox width="100%">
                    <SoftTypography fontWeight="bold" fontSize="20px" mb={0.5}>
                      Time Left
                    </SoftTypography>
                    <TextField
                      value={
                        addInspectionData?.left_time
                          ? getTimeFromMilliseconds(
                              addInspectionData?.left_time
                            )
                          : "Select Left Time"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDialogue({
                          type: "openTimePicker",
                          isOpen: true,
                          action: "addleft",
                        });
                      }}
                      name="name"
                      placeholder="Enter Left Time"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        sx: {
                          border: "1px solid #6A707C",
                          backgroundColor: "#FFFFFF",
                          paddingX: "14px", // Adjust left/right padding
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontSize: "16px",
                          color: "#02255B",
                          paddingY: "16px",
                          minHeight: "28px",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox width="100%" mt="20px" px={3}>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SoftTypography
                        color="lightBlack"
                        fontSize="20px"
                        fontWeight="bold"
                        sx={{ color: "#212121" }}
                      >
                        Deficiencies Found
                      </SoftTypography>
                    </SoftBox>

                    {discipline?.length == 0 && (
                      <SoftBox
                        display="flex"
                        gap="2px"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleOpenDiciplineSelect(0)}
                      >
                        <SoftTypography color="primary" sx={{ mt: "6px" }}>
                          <AddIcon
                            sx={{
                              width: 20,
                              height: 20,
                            }}
                          />
                        </SoftTypography>

                        <SoftTypography
                          color="primary"
                          fontSize="16px"
                          fontWeight="medium"
                        >
                          Add Discipline
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>

                  <SoftBox
                    display="flex"
                    gap="70px"
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: "20px", sm: "70px" },
                    }}
                  >
                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SoftBox position="relative" mt={1} mr={0.5}>
                        {addInspectionData?.deficiencies_found?.includes(
                          "no"
                        ) ? (
                          <SoftBox
                            component="img"
                            src={checkboxgreen}
                            sx={{
                              width: 20,
                              height: 20,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setAddInspectionData((prev) => ({
                                ...prev,
                                deficiencies_found:
                                  prev.deficiencies_found.filter(
                                    (item) => item !== "no"
                                  ),
                              }))
                            }
                          />
                        ) : (
                          <SoftBox
                            component="img"
                            src={checkboxwhite}
                            sx={{
                              width: 20,
                              height: 20,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setAddInspectionData((prev) => ({
                                ...prev,
                                deficiencies_found: [
                                  ...prev.deficiencies_found,
                                  "no",
                                ],
                              }))
                            }
                          />
                        )}
                      </SoftBox>

                      <SoftTypography
                        color="lightBlack"
                        fontSize="16px"
                        fontWeight="medium"
                        display="flex"
                        sx={{ color: "#212121" }}
                      >
                        No
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SoftBox position="relative" mt={1} mr={0.5}>
                        {addInspectionData?.deficiencies_found?.includes(
                          "yes"
                        ) ? (
                          <SoftBox
                            component="img"
                            src={checkboxgreen}
                            sx={{
                              width: 20,
                              height: 20,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setAddInspectionData((prev) => ({
                                ...prev,
                                deficiencies_found:
                                  prev.deficiencies_found.filter(
                                    (item) => item !== "yes"
                                  ),
                              }))
                            }
                          />
                        ) : (
                          <SoftBox
                            component="img"
                            src={checkboxwhite}
                            sx={{
                              width: 20,
                              height: 20,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setAddInspectionData((prev) => ({
                                ...prev,
                                deficiencies_found: [
                                  ...prev.deficiencies_found,
                                  "yes",
                                ],
                              }))
                            }
                          />
                        )}
                      </SoftBox>

                      <SoftTypography
                        color="lightBlack"
                        fontSize="16px"
                        fontWeight="medium"
                        display="flex"
                        sx={{ color: "#212121" }}
                      >
                        Yes
                        <SoftTypography
                          color="lightBlack"
                          fontSize="16px"
                          fontWeight="regular"
                          display="flex"
                          sx={{ color: "#212121" }}
                        >
                          (see comments below)
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SoftBox position="relative" mt={1} mr={0.5}>
                        {addInspectionData?.deficiencies_found?.includes(
                          "reinspection_required"
                        ) ? (
                          <SoftBox
                            component="img"
                            src={checkboxgreen}
                            sx={{
                              width: 20,
                              height: 20,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setAddInspectionData((prev) => ({
                                ...prev,
                                deficiencies_found:
                                  prev.deficiencies_found.filter(
                                    (item) => item !== "reinspection_required"
                                  ),
                              }))
                            }
                          />
                        ) : (
                          <SoftBox
                            component="img"
                            src={checkboxwhite}
                            sx={{
                              width: 20,
                              height: 20,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setAddInspectionData((prev) => ({
                                ...prev,
                                deficiencies_found: [
                                  ...prev.deficiencies_found,
                                  "reinspection_required",
                                ],
                              }))
                            }
                          />
                        )}
                      </SoftBox>

                      <SoftTypography
                        color="lightBlack"
                        fontSize="16px"
                        fontWeight="medium"
                        display="flex"
                        sx={{ color: "#212121" }}
                      >
                        Re-Inspection Required
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>

                {discipline && (
                  <SoftBox
                    px={3}
                    py={2}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <InspectionSection
                      discipline={discipline}
                      setDiscipline={setDiscipline}
                      newImages={newImages}
                    />
                  </SoftBox>
                )}
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>

      <SelectDicipline
        discipline={discipline}
        setDiscipline={setDiscipline}
        newImages={newImages}
        setNewImages={setNewImages}
      />

      <AddDicipline />
      <AddLocation />
      <SelectInspection
        projectid={id}
        referenceData={referenceData}
        setReferenceData={setReferenceData}
        setAddInspectionData={setAddInspectionData}
      />
    </DashboardLayout>
  );
}

export default AddInspection;
